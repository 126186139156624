










































import { Component } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import CarActionsOtelLogs from '@/modules/cars/modules/open-telemetry/mixins/car-actions-otel-logs.mixin';
// @ts-ignore
import AuthService, { AuthServiceS } from '@/modules/auth/auth.service';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import CarsAlertComponent from '@/modules/cars/alerts/components/cars-alert-component.vue';
import ConfigService, { ConfigServiceS } from '@/modules/config/config.service';
import CarsProfileMenu, { ICarsProfileMenuItem } from '@/modules/cars/components/cars-profile-menu.vue';
import BadRequestException from '@/modules/common/modules/exception-handler/exceptions/bad-request.exception';
import CarsSharedService, { CarsSharedServiceS } from '@/modules/cars/cars-shared.service';
import CustomDropdown from '@/modules/common/components/ui-kit/custom-dropdown.vue';
import JiraService, { JiraServiceS } from '@/modules/jira-chat/jira.service';
import CustomerSelect from './customer-select.vue';

@Component({
    components: {
        CarsAlertComponent,
        CarsProfileMenu,
        CustomDropdown,
        CustomerSelect,
    },
})
export default class CarsHeader extends CarActionsOtelLogs {
    @Inject(AuthServiceS) protected authService!: AuthService;
    @Inject(UserServiceS) private userService!: UserService;
    @Inject(ConfigServiceS) configService!: ConfigService;
    @Inject(CarsSharedServiceS) private carsSharedService!: CarsSharedService;
    @Inject(JiraServiceS) private jiraService!: JiraService;

    private isWidgetFormEnabled: boolean = false;

    mounted() {
        this.isWidgetFormEnabled = this.jiraService.isWidgetFormEnabled();
        if (this.isWidgetFormEnabled) {
            setTimeout(() => {
                this.jiraService.initWidgetForm();
            }, 5000);
        }
    }

    get items() {
        return [
            {
                link: 'https://fornova-support.atlassian.net/servicedesk/customer/topic/4c9e963e-756d-4032-9af2-deee3ada53e9',
                text: 'Knowledge Base',
            },
            {
                link: 'https://fornova-support.atlassian.net/servicedesk/customer/portal/2/group/3/create/10',
                text: 'New Support Ticket',
            },
        ];
    }

    get dropdownItems(): ICarsProfileMenuItem[] {
        const {
            isHotelUser,
            isCarUser,
            isAdmin,
        } = this.userService;

        const { isBrandMode, generalSettings, userManagement, isChildChain } = this.carsSharedService;

        return [
            {
                text: 'Rooms Type Manager',
                link: 'rooms-mapping',
                isVisible: isHotelUser || false,
            },
            {
                text: 'Car category mapping',
                link: 'cars-mapping',
                isVisible: isCarUser || false,
            },
            {
                text: 'Fleet SIPP code mappings',
                link: 'sipp',
                isVisible: isCarUser || false,
            },
            {
                text: 'User Settings',
                link: 'car-user-settings',
                isVisible: (isCarUser || false),
                isParentURL: true,
            },
            {
                text: 'General Settings',
                link: 'car-general-settings',
                isVisible: (isCarUser && isBrandMode && generalSettings) || false,
                isParentURL: true,
            },
            {
                text: 'View as',
                link: '',
                viewAs: true,
            },
            {
                text: 'Admin',
                link: '',
                isVisible: isAdmin && userManagement,
                action: this.toAdmin,
            },
            {
                text: 'Log Out',
                link: '',
                action: this.logout,
            },
        ];
    }

    get logo() {
        const images = require.context('@/modules/common/assets/', false, /\.png$|\.jpg$|\.svg$/);
        if (!this.carsSharedService.theme || !this.carsSharedService.theme.logo) {
            return images('./fornova-cr-logo.svg');
        }

        return `${this.carsSharedService.theme.logo}?v=2`;
    }

    get defaultLogo() {
        return '@/modules/common/assets/fornova-cr-logo.svg';
    }

    get userName() {
        const { user } = this.userService.storeState;
        if (!user) {
            return null;
        }

        return `${user.firstName} ${user.lastName}`;
    }

    get userEmail() {
        const { user } = this.userService.storeState;
        if (!user) {
            return null;
        }

        return user.email;
    }

    get diAdminUrl() {
        return this.configService.diAdminUrl;
    }

    toAdmin() {
        if (!this.diAdminUrl) {
            throw new BadRequestException('No VUE_APP_DI_URL specified in the app.config.json!');
        }
        this.addOtelLogs('admin-page');
        window.location.href = `${this.diAdminUrl}/login-token/${encodeURIComponent(localStorage.getItem('token') as string)}`;
    }

    logout() {
        this.addOtelLogs('logout');
        this.authService.logout();
    }
}
